<template>
  <esmp-select
    class="search-user"
    clearable
    :placeholder="placeholder"
    filterable
    :loading="loading"
    :show-search-results="showSearchResults"
    :remote-method="waitClientPrint"
    @on-query-change="setQuery"
    @on-select="setClientSelectedVal"
    @keydown.enter.native="searchClient"
    :disabled="disabled"
    v-model="localValue"
  >
    <esmp-select-option
      v-for="r in clientSearchList"
      :value="r.login"
      :label="r.fullName"
      :key="r.login"
    >
      <div class="search-user__item">
        <div class="search-user__user-contact">
          <span class="search-user__user-name">{{ r.fullName }}</span>
          <span class="search-user__user-email" v-if="r.email">{{ r.email }}</span>
        </div>
        <span class="search-user__user-appointment" v-if="r.appointmentName">
          {{ r.appointmentName }}
        </span>
      </div>
    </esmp-select-option>
  </esmp-select>
</template>

<script>
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'SearchUser',
  props: {
    user: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Поиск сотрудников',
    },
  },
  data() {
    return {
      clientSearchList: null,
      query: '',
      loading: false,
      showSearchResults: false,
      searchTimeoutId: null,
      selectedUser: null,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.user;
      },
      set(newValue) {
        this.$emit('update:user', newValue);
      },
    },
  },
  methods: {
    setQuery(val) {
      this.query = val;
    },
    setClientSelectedVal(val) {
      this.selectedUser = val;
      this.localValue = val.value;
    },
    waitClientPrint() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (!this.query || this.query.length < 3) {
        this.showSearchResults = false;
      }
      if (this.query && this.query.length >= 3) {
        this.showSearchResults = true;
        this.loading = true;
        this.searchTimeoutId = setTimeout(() => {
          this.searchClient();
        }, SEARCH_DELAY);
      } else {
        this.loading = false;
      }
    },
    async searchClient() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      if (this.query) {
        this.loading = true;
        const { data } = await this.$API.user.search({
          query: this.query,
        });
        this.clientSearchList = data;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .search-user {
    &__item {

      &:not(:last-child) {
        margin-right: 32px;
      }

      &--wide {
        width: 440px;
      }
    }

    &__user-contact {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &__user-name {
      margin-right: 16px;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      transition: color $base-animation-time;
    }

    &__user-email {
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-50;
      margin-right: 16px;
    }

    &__user-appointment {
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-40;
    }
  }
</style>
